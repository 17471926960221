

.card-lesson {
    margin-top: 30px;
}

.card-lesson .card-body {
    height: 200px;
}

.card-lesson.card-lesson-complete {

}

.card-img-container {
    position: relative;
}

.card-img-container > .card-img-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -75px 60px 0 #00000034;
    top: 0;
    left: 0;
    transition: box-shadow 0.5s ease;
}

.card-lesson:hover .card-img-container > .card-img-shadow {
    box-shadow: inset 0px 0px 100px 0 #0000001f;
}

.card-img-progress {
    position: absolute;
    bottom: -8px;
    left:0px;
    width: 100%;
}

@media only screen and (max-device-width: 375px) {
    .card-lesson .card-body {
        height: 220px;
    }
}
