body {
    padding: 0;
    margin: 0;
    background-color: rgb(248, 248, 248);
}

html {
    scroll-behavior: smooth;
}

.main-content-container-non-readable {
    filter: blur(10px);
}

.navbar {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
    overflow: hidden;
    background-color: white !important;
    padding: 0.5rem 2rem;
    position: fixed;
    position: -webkit-sticky; /*for safari*/
    position: sticky; /* for Firefox */
    top: 0;
    height: 64px;
    width: 100%;
    z-index: 100;
}

.card {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.09);
}
.navbar-row  {
    height: 64px;
    width: 100%;
    min-width: 0;
}

.navbar-home-col {
    max-width: 60px;
    width: 60px;
    padding: 0;
}
.navbar-home-icon  {
    width: 44px;
    height: 64px;
}

.navbar-title {
    height: 52px;
    line-height: 2;
    font-size: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: normal;
}

.primary-logo {
    text-align: right;
}

.primary-logo.col,
.secondary-logo.col,
.tertiary-logo.col {
    display: flex;
}

.primary-logo img,
.secondary-logo img,
.primary-logo-iconic,
.secondary-logo-iconic {
    height: 52px;
    margin: 6px;
}
.tertiary-logo img,
.tertiary-logo-iconic {
    max-width: 400px;
    max-height: 52px;
    height: auto;
    margin: 6px;
}

footer {
    font-size: 20px;
    background-color: rgba(139, 139, 139, 0.048);
    padding: 10px 0;
}

footer .footer-links-col a {
    color: rgb(0, 0, 0);
}

.course-list-section {
    background-color: rgba(139, 139, 139, 0.048);
}

@media only screen and (orientation: landscape) {
    .course-intro-section {
        min-height: calc(100vh - 79px)
    }
}

.course-list-bonus-section {
    background-color: rgba(139, 139, 139, 0.157);
}

.course-list-section h3 {
    margin: 15px 15px 5px 15px;
}

.course-intro-section {
    padding: 10px 15px;
    margin: 15px 15px;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.course-intro-section h3 {
    font-size: 2em;
    margin-bottom: 15px;
}

.course-header-section {
}

.main-content-container {
    padding: 0;
    margin: 0 auto 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
    background-color: white;
    min-height: calc(100vh - 133px);
}

.section-container {
    padding: 0;
    margin: 0;
}

.section-article-content {
    padding: 1em 2em;
}

.lesson-article-container {
    max-width: 1240px;
}

@media only screen and (max-device-width: 375px) {
	.navbar-title {
        line-height: 1.2;
        font-size: 1.5em;
        word-break: normal;
        white-space: normal;
    }

    .tertiary-logo img, .tertiary-logo a {
        max-width: 190px;
        margin-top: auto;
        margin-bottom: auto;
    }

    footer {
        font-size: 18px;
    }

    .footer-nav .footer-links-col:last-child {
        text-align: right;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) {
	.navbar-title {
        line-height: 2.7;
        font-size: 1.5em;
        word-break: normal;
        white-space: normal;
    }

    .tertiary-logo img, .tertiary-logo a {
        max-width: 230px;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.lesson-index-row {
    margin: 0;
}

.course-list-section-inner {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 12px 28px 12px;
}

.footer-nav {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 15px;
}

.jumbotron {
    margin-bottom: 0;
}

@media only screen and (min-device-width: 1200px) {
    .primary-logo img {
        border-right: 2px solid black;
    }
}
