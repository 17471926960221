
.lesson-video {
    width: 100%;
    background-color: darkgray;
}

.course-intro-section p {
    font-size: 1.2rem;
    font-weight: normal;
}

.lesson-index-video-row {
    width: calc(100% - 10px);
    margin: 5px;
    display: -webkit-box;
}
