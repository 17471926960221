.lesson-viewport-wrapper {
    position: relative;
}

.lesson-view-port {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0px;
}

.lesson-view-container  {
    padding: 0px;
    overflow: hidden;
}

.lesson-view-container > .section-container {
}
