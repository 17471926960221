
.lesson-header-container {
    position: relative;
    padding: 8rem 2rem 10rem 2rem;
    overflow: hidden;
    border-radius: 0;
}

.lesson-header-buttons {
    position: absolute;
    bottom: 100px;
    width: 100%;
    left: 15px;
    z-index: 10;
}

.lesson-header-video {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
}

.lesson-header-video.video-overlay {
    z-index: 2;
    opacity: 0.3;
    background-color: lightgrey;
}

.lesson-header-image {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    opacity: 1;
}

.lesson-header-header {
    position: absolute;
    z-index: 5;
    top: 60px;
    color: white;
    margin: auto;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.383);
    text-align: center;
    width: calc(100% - 4rem);
    max-height: 50vh;
    padding: 80px 100px 0 100px;
}

.lesson-header-header.slide-in {
    animation: slide-in 0.5s forwards;
    transform: translateX(+100%);
}

.lesson-header-header.slide-out {
    animation: slide-out 0.5s forwards;
    transform: translateX(-100%);
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

.lesson-header-header h1 {
    word-wrap: break-word;
    height: 40vh;
    font-weight: bold;
    overflow: hidden;
}

.lesson-header-btn {
    padding: 15px;
    font-size: 24px;
    border: 2px solid rgba(247, 247, 247, 0.7);
    border-radius: 6px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: background-color 200ms ease-in-out;
    -moz-transition: background-color 200ms ease-in-out;
    -ms-transition: background-color 200ms ease-in-out;
    -o-transition: background-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;
}

.lesson-header-btn:hover {
    color: rgb(50, 50, 50);
    border: 2px solid rgba(0, 0, 0, 0.6);
    background-color: rgba(247, 247, 247, 0.7);
    -webkit-transition: background-color 300ms ease-in-out;
    -moz-transition: background-color 300ms ease-in-out;
    -ms-transition: background-color 300ms ease-in-out;
    -o-transition: background-color 300ms ease-in-out;
    transition: background-color 300ms ease-in-out;
}

@media only screen and (max-device-width: 480px) {
    .lesson-header-header {
        padding: 0;
    }
    .lesson-header-header h1 {
        font-size: 28px;
        height: 50vh;
    }
    .lesson-header-buttons {
        bottom: 0;
    }
    .lesson-header-btn {
        border: 0;
        border-radius: 2px;
    }
    .lesson-header-btn:hover {
        border: 0;
    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 767px) {
    .lesson-header-header {
        padding: 0;
    }
    .lesson-header-header h1 {
        height: 50vh;
    }
    .lesson-header-buttons {
        bottom: 0;
    }
    .lesson-header-btn {
        border: 0;
        border-radius: 2px;
    }
    .lesson-header-btn:hover {
        border: 0;
    }
}

@media only screen and (min-device-width: 767px) and (max-device-width: 992px) {
    .lesson-header-buttons {
        bottom: 50px;
    }
    .lesson-header-btn {
        font-size: 20px;
    }
}

@media only screen and (min-device-width: 1200px) {
    .lesson-header-btn {
        font-size: 28px;
    }
}
